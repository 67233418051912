import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './WorkCard.css';

const WorkCard = (props) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const resizeImage = () => {
      if (imageRef.current) {
        const containerWidth = imageRef.current.parentNode.offsetWidth;
        const imageWidth = imageRef.current.naturalWidth;
        const imageHeight = imageRef.current.naturalHeight;

        const aspectRatio = imageWidth / imageHeight;
        const calculatedHeight = containerWidth / aspectRatio;

        imageRef.current.style.height = `${calculatedHeight}px`;
      }
    };

    window.addEventListener('resize', resizeImage);
    resizeImage();

    return () => {
      window.removeEventListener('resize', resizeImage);
    };
  }, []);

  return (
    <div className='project-card'>
      <img src={props.imgsrc} alt='' ref={imageRef} />
      <h2 className='project-title'>{props.title}</h2>
      <div className='pro-details'>
        <p>{props.text}</p>
        <div className='pro-btns'>
          <NavLink to={props.view} className='btn'>
            View
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
