import { Link } from 'react-router-dom';
import './AboutContent.css';

import React from 'react'
import React1 from '../assets/bar-area.jpg'
import React2 from '../assets/lobby-seating.jpg'


const AboutContent = () => {
  return (
    <div className='about'>
      <div className='left'>
        <h1>Welcome to Cambium Carpentry LLC!</h1>
        <p>We are a team of skilled carpenters dedicated to crafting exceptional finishes for hotels and hospitality establishments. With meticulous attention to detail and a commitment to excellence, we bring your vision to life, transforming spaces into unforgettable experiences for your guests. From custom millwork to intricate molding, our experienced artisans deliver outstanding results. We prioritize quality, sustainability, and client satisfaction. Explore our projects portfolio and let us collaborate on creating remarkable spaces for your hotel.</p>
        <Link to='/contact'>
            <button className='btn'>Contact</button>
        </Link>
      </div>
      <div className='right'>
        <div className='img-container'>
            <div className='img-stack top'>
                <img src={React1} className='img' alt='true'/>
            </div>
            <div className='img-stack bottom'>
                <img src={React2} className='img' alt='true'/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
