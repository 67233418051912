import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../components/ImageGallery.css';
import doorImage1 from '../assets/Doors/d1.jpg';
import doorImage2 from '../assets/Doors/d2.jpg';
import doorImage3 from '../assets/Doors/d3.jpg';
import doorImage from '../assets/Doors/Door1.jpg';
import barndoorImage from '../assets/Doors/Door2.jpg';

import Navbar from './Navbar';
import Footer from './Footer';

const DoorGallery = () => {
    // Sample images data
    const images = [
      {
        original: doorImage1,
        thumbnail: doorImage1,
        description: 'Barn Doors',
      },
      {
        original: doorImage2,
        thumbnail: doorImage2,
        description: 'Barn Doors',
      },
      {
        original: doorImage3,
        thumbnail: doorImage3,
        description: 'Barn Doors',
      },
      {
        original: doorImage,
        thumbnail: doorImage,
        description: 'Doors',
      },
      {
        original: barndoorImage,
        thumbnail: barndoorImage,
        description: 'Barn Doors',
      },

      // Add more images as needed
    ];
  
    return (
      <div>
        <Navbar />
        <div className="image-gallery-container">
          <ImageGallery items={images} />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default DoorGallery;
  