import React from 'react';
import './index.css';
import Home from './routes/Home';
import About from './routes/About';
import Projects from './routes/Projects';
import Contact from './routes/Contact';
import { Route, Routes } from 'react-router-dom';
import MyImageGallery from './components/ImageGallery';
import DoorGallery from './components/DoorGallery'
import BarGallery from './components/BarGallery'
import LavatoryGallery from './components/LavatoryGallery'
import LobbyGallery from './components/LobbyGallery'
import OutdoorsGallery from './components/OutdoorsGallery';




function App() {
  return (
    
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='ImageGallery' element={<MyImageGallery />} />
        <Route path='BarGallery' element={<BarGallery />} />
        <Route path='DoorGallery' element={<DoorGallery />} />
        <Route path='LavatoryGallery' element={<LavatoryGallery />} />
        <Route path='LobbyGallery' element={<LobbyGallery />} />
        <Route path='OutdoorsGallery' element={<OutdoorsGallery />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </>
    );
}

export default App;
