import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../components/ImageGallery.css';
import gazeboImage3 from '../assets/Outdoors/Gazebo_3.jpg';
import gazeboImage4 from '../assets/Outdoors/Gazebo_4.jpg';
import trellisImage from '../assets/Outdoors/Trellis.jpg';
import trellisImage2 from '../assets/Outdoors/Trellis_2.jpg';
import trellisImage3 from '../assets/Outdoors/Trellis_3.jpg';
import trellisImage4 from '../assets/Outdoors/Trellis_4.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const OutdoorsGallery = () => {
    // Sample images data
    const images = [
      {
        original: gazeboImage3,
        thumbnail: gazeboImage3,
        description: 'Littleton, CO - Gazebo',
      },
      {
        original: gazeboImage4,
        thumbnail: gazeboImage4,
        description: 'Littleton, CO - Gazebo',
      },
      {
        original: trellisImage,
        thumbnail: trellisImage,
        description: 'Littleton, CO - Trellis',
      },
      {
        original: trellisImage2,
        thumbnail: trellisImage2,
        description: 'Littleton, CO - Trellis',
      },
      {
        original: trellisImage3,
        thumbnail: trellisImage3,
        description: 'Littleton, CO - Trellis',
      },
      {
        original: trellisImage4,
        thumbnail:  trellisImage4,
        description: 'Littleton, CO - Trellis',
      },
      // Add more images as needed
    ];
  
    return (
      <div>
        <Navbar />
        <div className="image-gallery-container">
          <ImageGallery items={images} />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default OutdoorsGallery;
  