import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../components/ImageGallery.css';
import baseboardsImage from '../assets/Millwork/p1.jpg';
import printstationImage from '../assets/Millwork/p2.jpg';
import lobbySeatingImage from '../assets/Millwork/p3.jpg';
import bathroomImage from '../assets/Millwork/p4.jpg';
import barAreaImage from '../assets/Millwork/p5.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const MyImageGallery = () => {
  // Sample images data
  const images = [
    {
      original: baseboardsImage,
      thumbnail: baseboardsImage,
      description: 'Lobby, Millwork',
    },
    {
      original: bathroomImage,
      thumbnail: bathroomImage,
      description: 'Millwork',
    },
    {
      original: printstationImage,
      thumbnail: printstationImage,
      description: 'Lobby, Millwork',
    },
    {
      original: lobbySeatingImage,
      thumbnail: lobbySeatingImage,
      description: 'Lobby, Millwork',
    },
    {
      original: barAreaImage,
      thumbnail: barAreaImage,
      description: 'Millwork and Cabinets',
    },
    // Add more images as needed
  ];

  return (
    <div>
      <Navbar />
      <div className="image-gallery-container">
        <ImageGallery items={images} />
      </div>
      <Footer />
    </div>
  );
};

export default MyImageGallery;
