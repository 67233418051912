import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Work from '../components/Work';
import '../components/Projects.css';



const Project = () => {
  return (
    <div>
      <Navbar />
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
      <Work />
      <Footer />
    </div>
  );
};

export default Project;
