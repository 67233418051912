import p1 from '../assets/Millwork/p1-resized.jpg';
import p2 from '../assets/Doors/Door2-resized.jpg';
import p3 from '../assets/Lobby/lobby-seating-resize.jpg';
import p4 from '../assets/bathroom.jpg';
import p5 from '../assets/bar-area.jpg';
import p6 from '../assets/Outdoors/Trellis.jpg';



const ProjectCardData = [
    {
        imgsrc: p1,
        title: 'Millwork',
        text: 'We install baseboard, crown, handrail, trim work',
        view: '../ImageGallery'
        
    },
    {
        imgsrc: p2,
        title: 'Doors',
        text: 'We install doors',
        view: '../DoorGallery'
    },
    {
        imgsrc: p3,
        title: 'Lobby | Buffet',
        text: 'We install hotel lobby packages',
        view: '../LobbyGallery'
    },
    {
        imgsrc: p4,
        title: 'Lavatory',
        text: 'We install bathroom vanities & partitions',
        view: '../LavatoryGallery'
    },
    {
        imgsrc: p5,
        title: 'Bar',
        text: 'We install custom bars',
        view: '../BarGallery'
    },
    {
        imgsrc: p6,
        title: 'Outdoors | Timber Projects',
        text: 'Gazebos, Trellis',
        view: '../OutdoorsGallery'
    },
];


export default ProjectCardData;