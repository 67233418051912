import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../components/ImageGallery.css';
import baseboardsImage from '../assets/Bar/Bar_1.jpg';
import printstationImage from '../assets/Bar/Bar_2.jpg';
import lobbySeatingImage from '../assets/Bar/Bar_3.jpg';
import barAreaImage6 from '../assets/Bar/Bar_6.jpg';
import barAreaImage14 from '../assets/Bar/Bar_14.jpg';
import barAreaImage17 from '../assets/Bar/Bar_17.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const BarGallery = () => {
    // Sample images data
    const images = [
      {
        original: baseboardsImage,
        thumbnail: baseboardsImage,
        description: 'Pullman, WA - Bar and cabinets',
      },
      {
        original: printstationImage,
        thumbnail: printstationImage,
        description: 'Pullman, WA - Bar and cabinets',
      },
      {
        original: lobbySeatingImage,
        thumbnail: lobbySeatingImage,
        description: 'Pullman, WA - Bar and cabinets REMOVE',
      },

      {
        original: barAreaImage6,
        thumbnail: barAreaImage6,
        description: 'Pullman, WA - Bar and cabinets',
      },
      {
        original: barAreaImage14,
        thumbnail: barAreaImage14,
        description: 'Bellevue, WA - Bar and cabinets',
      },
      {
        original: barAreaImage17,
        thumbnail: barAreaImage17,
        description: 'Bellevue, WA - Bar and cabinets',
      },
      // Add more images as needed
    ];
  
    return (
      <div>
        <Navbar />
        <div className="image-gallery-container">
          <ImageGallery items={images} />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default BarGallery;
  