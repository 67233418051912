import './Footer.css';

import React from 'react'

import { FaFacebook, FaTwitter, FaLinkedin, FaHome, FaMailBulk, FaPhone } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='left'>
            <div className='location'>
                <FaHome size={20} style={{ color: '#fff', marginRight: '2rem' }} />
                <div>
                  <p>Folsom, California USA</p>
                </div>
            </div>
            <div className='phone'>
                <h4><FaPhone size={20} style={{ color: '#fff', marginRight: '2rem' }} /> 
    916-996-7550</h4>
            </div>
            <div className='email'>
                <h4><FaMailBulk size={20} style={{ color: '#fff', marginRight: '2rem' }} /> 
    CambiumRocks@gmail.com</h4>
            
            </div>
        </div>
        <div className='right'>
            <h4>About the company</h4>
            <p>We do what we love and love what we do. </p>
            <div className='social'>
            <a href="./"><FaFacebook size={30} style={{ color: '#fff', marginRight: '1rem' }} /></a>
            <a href="./"><FaTwitter size={30} style={{ color: '#fff', marginRight: '1rem' }} /></a>
            <a href="./"><FaLinkedin size={30} style={{ color: '#fff', marginRight: '1rem' }} /></a>

            </div>
        </div>

      </div>
    </div>
  )
}

export default Footer
