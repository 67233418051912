import './ContactForm.css';
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';





function ContactForm() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1kjnld9', 'template_kot5kj6', form.current, 'WigBbK65q7zS1YYPY')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()

        alert("Email Sent!");
    };

    return (
        <div className='container'>
            <h3>Contact Us</h3>
            <form ref={form} onSubmit={sendEmail}>
                <label>Name  </label>
                <input type='text' name ="user_name" required/>
                <label>Email  </label>
                <input type='email' name="user_email" required/>
                <label>Message</label> 
                <textarea name='message' required/>
                <input type="submit" name='submit' className='btn' value="Send" />
            </form>
        </div>
    );
};

export default ContactForm;
