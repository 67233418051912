import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../components/ImageGallery.css';
import l1Image from '../assets/Lobby/lobby4.jpg';
import l2Image from '../assets/Lobby/lobby5.jpg';
import l3Image from '../assets/Lobby/lobby6.jpg';
import l4Image from '../assets/lobby-seating.jpg';
import l5Image from '../assets/Millwork/p1.jpg';
import Navbar from './Navbar';
import Footer from './Footer';

const MyImageGallery = () => {
  // Sample images data
  const images = [
    {
        original: l5Image,
        thumbnail: l5Image,
        description: 'Lobby',
      },
    {
      original: l1Image,
      thumbnail: l1Image,
      description: 'Lobby',
    },
    {
      original: l2Image,
      thumbnail: l2Image,
      description: 'Buffet',
    },
    {
      original: l3Image,
      thumbnail: l3Image,
      description: 'Buffet',
    },
    {
        original: l4Image,
        thumbnail: l4Image,
        description: 'Lobby',
      },
    // Add more images as needed
  ];

  return (
    <div>
      <Navbar />
      <div className="image-gallery-container">
        <ImageGallery items={images} />
      </div>
      <Footer />
    </div>
  );
};

export default MyImageGallery;
