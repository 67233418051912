import './HeroImg.css';

import React from 'react'

import IntroImg from '../assets/intro-img.jpg'
import { Link } from 'react-router-dom';

const HeroImg = () => {
  return (
    <div className='hero'>
        <div className='mask'>
            <img className='intro-img'
            src={IntroImg} alt=''/>
        </div>
        <div className='content'>
            <p>Do what you love. Love what you do.</p>
            <h1>Cambium Carpentry</h1>
            <div>
                <Link to='/projects' className='btn'>Projects</Link>
                <Link to='/contact' className='btn' >Contact</Link>
            </div>

        </div>
      
    </div>
  );
};

export default HeroImg;
